<template>
  <div class="Collection container">
    <userState />
    <div v-if="collectionList.length" class="collection-list">
      <template v-for="(item, index) in collectionList" :key="index">
        <GoodsCard class="db-mb10" :goods-info="item" />
      </template>
      <template v-for="(item, index) in 5 - collectionList.length % 5" :key="index">
        <div style="width: 244px; height: 392px;" />
      </template>
    </div>
    <div v-else style="height: 400px;padding-top: 100px;">
      <a-empty :description="'暂无商品，请选择商品收藏'" />
    </div>
  </div>
</template>

<script>
import userState from '@/views/user/components/userState'
import { getCollectSkus } from '@/api/goods'
import GoodsCard from '@/components/goodsCard'
export default {
  name: 'Collection',
  components: {
    userState,
    GoodsCard
  },
  data() {
    return {
      collectionList: []
    }
  },
  created() {
    this.getCollectSkusFun()
  },

  methods: {
    getCollectSkusFun() {
      getCollectSkus().then(res => {
        this.collectionList = res.data || []
      })
    }
  }
}
</script>
<style lang='less' scoped>
.collection-list {
  margin-top: 16px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
